import * as React from "react"

import {Link} from 'gatsby'
import Layout from '../../../components/layout/layout'

const pageTitle = "Frequencies"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">


                        <h3 className="pt-4 pb-4">
                            <a href="/en/pages/frequencies/">Hong Kong Radio Frequencies 香港無線電頻道</a>
                        </h3>
                        <hr/>

                        <p className="text-muted">2015-07-08 </p>


                        <div className="panel panel-red">
                            <div className="panel-heading">Important Information 重要提醒</div>
                            <div className="panel-body">
                                <p>This table lists all the common freqencies that can be listened in Hong Kong.</p>

                                <p>本表列出大部份可以在香港境内聽到之無線電頻道。</p>

                                <p>Please be aware that the transmission of the radio frequencies are regulated and
                                    allowed only to licensed operators, such as the air bands, amateur radio as well
                                    as commercial FM and AM radio. Exemptions are available, for example at 409MHz,
                                    GSM channels, 2.4GHz and 5.8GHz, although subjected to equivalent isotropically
                                    radiated power regulations as well as selected frequency hopping
                                    applications.</p>

                                <p>請留意，只有持合法牌照之人士及機構，方可在不同頻道操作無線電台或發射。409MHz, GSM 手機及 2.4GHz/5.8GHz
                                    等獲豁免頻道則不在此限，雖然受相關發射功率或只限跳頻應用。</p>

                                <p>Also, please beware that some UHF HAM repeaters in China uses 433-435MHz uplink,
                                    which is not licensed in Hong Kong. Those are displayed
                                    as <strike>underline</strike> below.</p>

                                <p>另外請留意，深圳及珠海的 UHF 中繼站大部份都是在
                                    433-435MHz，不是在香港無線電頻道之內。不可用的都會以 <strike>underline</strike> 型式顯示。</p>
                            </div>
                        </div>


                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <td>
                                    Title / Callsign
                                </td>
                                <td>
                                    QTH 登記設台位
                                </td>
                                <td>
                                    Downlink 下行頻 (MHz)
                                </td>
                                <td>
                                    Uplink 上行頻 (MHz)
                                </td>
                                <td>
                                    TSQL
                                </td>
                            </tr>
                            </thead>
                            <tbody>

                            <tr>
                                <td>
                                    APRS
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    144.64
                                </td>
                                <td>
                                    144.64
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2RCV
                                </td>
                                <td>
                                    Yau Ma Tei 油麻地
                                </td>
                                <td>
                                    145.25
                                </td>
                                <td>
                                    144.65
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2CHK
                                </td>
                                <td>
                                    Tai Po 大埔
                                </td>
                                <td>
                                    145.7
                                </td>
                                <td>
                                    145.3
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2Z
                                </td>
                                <td>
                                    Tai Tung Shan 大東山
                                </td>
                                <td>
                                    144.425
                                </td>
                                <td>
                                    144.825
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2TKW
                                </td>
                                <td>
                                    Tokwawan 土瓜灣
                                </td>
                                <td>
                                    145.575
                                </td>
                                <td>
                                    144.975
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2A
                                </td>
                                <td>
                                    Mid Levels 半山
                                </td>
                                <td>
                                    145.625
                                </td>
                                <td>
                                    145.025
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2KP
                                </td>
                                <td>
                                    Tate's Cair 大老山
                                </td>
                                <td>
                                    145.65
                                </td>
                                <td>
                                    145.05
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2HAC
                                </td>
                                <td>
                                    Sai Kung 西貢
                                </td>
                                <td>
                                    145.675
                                </td>
                                <td>
                                    145.05
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2HKR
                                </td>
                                <td>
                                    North Point 北角
                                </td>
                                <td>
                                    145.7
                                </td>
                                <td>
                                    145.1
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2R
                                </td>
                                <td>
                                    Tai Mo Shan 大帽山
                                </td>
                                <td>
                                    145.725
                                </td>
                                <td>
                                    145.125
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2HKA
                                </td>
                                <td>
                                    The Peak 太平山
                                </td>
                                <td>
                                    145.75
                                </td>
                                <td>
                                    145.15
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2UTM
                                </td>
                                <td>
                                    Tin Shu Wai 天水圍
                                </td>
                                <td>
                                    145.775
                                </td>
                                <td>
                                    145.175
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2DSR
                                </td>
                                <td>
                                    Mid Level 半山
                                </td>
                                <td>
                                    145.825
                                </td>
                                <td>
                                    145.225
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2RDS
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    430.0125
                                </td>
                                <td>
                                    430.012
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2UTM
                                </td>
                                <td>
                                    Tin Shu Wai 天水圍
                                </td>
                                <td>
                                    435.225
                                </td>
                                <td>
                                    430.225
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2HKA
                                </td>
                                <td>
                                    The Peak 太平山
                                </td>
                                <td>
                                    435.25
                                </td>
                                <td>
                                    430.25
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2KP
                                </td>
                                <td>
                                    Tate's Cair 大老山
                                </td>
                                <td>
                                    435.275
                                </td>
                                <td>
                                    430.275
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2RCU
                                </td>
                                <td>
                                    Yau Ma Tei 油麻地
                                </td>
                                <td>
                                    435.325
                                </td>
                                <td>
                                    430.325
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2R
                                </td>
                                <td>
                                    Tai Mo Shan 大帽山
                                </td>
                                <td>
                                    435.4
                                </td>
                                <td>
                                    430.4
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2TIG Ngau Chi
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    435.45
                                </td>
                                <td>
                                    430.45
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2HAC
                                </td>
                                <td>
                                    Sai Kung 西貢
                                </td>
                                <td>
                                    435.475
                                </td>
                                <td>
                                    430.475
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2JAM
                                </td>
                                <td>
                                    Wan Chai 灣仔
                                </td>
                                <td>
                                    435.55
                                </td>
                                <td>
                                    430.55
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2SSP
                                </td>
                                <td>
                                    Shum Shui Po 深水埗
                                </td>
                                <td>
                                    435.575
                                </td>
                                <td>
                                    430.575
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2AR
                                </td>
                                <td>
                                    Chai Wan 柴灣
                                </td>
                                <td>
                                    435.6
                                </td>
                                <td>
                                    430.6
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2Z
                                </td>
                                <td>
                                    Lantau 大嶼山 大東山
                                </td>
                                <td>
                                    435.625
                                </td>
                                <td>
                                    430.625
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2CWA
                                </td>
                                <td>
                                    Shum Shui Po 深水埗
                                </td>
                                <td>
                                    435.65
                                </td>
                                <td>
                                    430.65
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2HKR
                                </td>
                                <td>
                                    North Point 北角
                                </td>
                                <td>
                                    435.7
                                </td>
                                <td>
                                    430.7
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2FAN
                                </td>
                                <td>
                                    Fanling 粉嶺
                                </td>
                                <td>
                                    435.725
                                </td>
                                <td>
                                    430.725
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2POL
                                </td>
                                <td>
                                    Sheung Wan 上環
                                </td>
                                <td>
                                    435.8
                                </td>
                                <td>
                                    430.8
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2FTU
                                </td>
                                <td>
                                    Mongkok 旺角
                                </td>
                                <td>
                                    435.825
                                </td>
                                <td>
                                    430.825
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2DMR
                                </td>
                                <td>
                                    Tai Po 大埔
                                </td>
                                <td>
                                    435.9
                                </td>
                                <td>
                                    430.9
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2HKP
                                </td>
                                <td>
                                    Lai King 荔景
                                </td>
                                <td>
                                    435.925
                                </td>
                                <td>
                                    430.925
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    VR2JPO
                                </td>
                                <td>
                                    Mid Level 半山
                                </td>
                                <td>
                                    435.95
                                </td>
                                <td>
                                    430.95
                                </td>
                                <td>
                                    110.9 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #1
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.75
                                </td>
                                <td>
                                    409.75
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #2
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.7625
                                </td>
                                <td>
                                    409.7625
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #3
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.775
                                </td>
                                <td>
                                    409.775
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #4
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.7875
                                </td>
                                <td>
                                    409.7875
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #5
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.8
                                </td>
                                <td>
                                    409.8
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #6
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.8125
                                </td>
                                <td>
                                    409.812
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #7
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.825
                                </td>
                                <td>
                                    409.825
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #8
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.8375
                                </td>
                                <td>
                                    409.8375
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #9
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.85
                                </td>
                                <td>
                                    409.85
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #10
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.8625
                                </td>
                                <td>
                                    409.8625
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #11
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.875
                                </td>
                                <td>
                                    409.875
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #12
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.8875
                                </td>
                                <td>
                                    409.8875
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #13
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.9
                                </td>
                                <td>
                                    409.9
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #14
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.9125
                                </td>
                                <td>
                                    409.9125
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #15
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.925
                                </td>
                                <td>
                                    409.925
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #16
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.9375
                                </td>
                                <td>
                                    409.9375
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #17
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.95
                                </td>
                                <td>
                                    409.95
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #18
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.9625
                                </td>
                                <td>
                                    409.9625
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #19
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.975
                                </td>
                                <td>
                                    409.975
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    409 #20
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    409.9875
                                </td>
                                <td>
                                    409.9875
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    ISS APRS
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    145.825
                                </td>
                                <td>
                                    145.825
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    ISS Voice SSTV
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    144.8
                                </td>
                                <td>
                                    145.2
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    ISS UHF Uplink
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    145.8
                                </td>
                                <td>
                                    437.8
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    SSTV Calling
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    144.5
                                </td>
                                <td>
                                    144.5
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Macau 北區太陽神站
                                </td>
                                <td>
                                    Macau 澳門北區
                                </td>
                                <td>
                                    145.35
                                </td>
                                <td>
                                    144.75
                                </td>
                                <td>
                                    82.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Macau XX9ARM
                                </td>
                                <td>
                                    Macau 澳門
                                </td>
                                <td>
                                    145.45
                                </td>
                                <td>
                                    145.85
                                </td>
                                <td>
                                    210.7 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Macau 中區火星站
                                </td>
                                <td>
                                    Macau 澳門中區
                                </td>
                                <td>
                                    144.725
                                </td>
                                <td>
                                    144.125
                                </td>
                                <td>
                                    82.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    珠海 #1
                                </td>
                                <td>
                                    Zhuhai 珠海
                                </td>
                                <td>
                                    439.725
                                </td>
                                <td>
                                    <strike>434.725</strike>
                                </td>
                                <td>
                                    82.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    珠海 #2
                                </td>
                                <td>
                                    Zhuhai 珠海
                                </td>
                                <td>
                                    145.55
                                </td>
                                <td>
                                    144.95
                                </td>
                                <td>
                                    82.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    中山
                                </td>
                                <td>
                                    Zhon Shan 中山
                                </td>
                                <td>
                                    439.775
                                </td>
                                <td>
                                    <strike>434.775</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #1 DMR
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    438.46
                                </td>
                                <td>
                                    430.46
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #2
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    439.36
                                </td>
                                <td>
                                    <strike>434.36</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #3
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    439.46
                                </td>
                                <td>
                                    <strike>434.46</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #4
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    439.46
                                </td>
                                <td>
                                    <strike>434.46</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #5
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    439.18
                                </td>
                                <td>
                                    <strike>434.18</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #6
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    439.18
                                </td>
                                <td>
                                    <strike>434.18</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #7
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    438.525
                                </td>
                                <td>
                                    <strike>433.525</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #8
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    438.525
                                </td>
                                <td>
                                    <strike>433.525</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 VHF
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    144.3
                                </td>
                                <td>
                                    144.7
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    深圳 #9
                                </td>
                                <td>
                                    ShenZhen 深圳
                                </td>
                                <td>
                                    438.55
                                </td>
                                <td>
                                    <strike>433.55</strike>
                                </td>
                                <td>
                                    88.5 Hz
                                </td>
                            </tr>

                            </tbody>
                        </table>

                        <p>* 以上 VR2 中繼及 409MHz 頻道資料來自「香港業餘無線電會 HKARA 」<br/>
                            * 中國大陸及澳門中繼資料來自 hellocq.net<br/>
                            * Airband 資料來自 Wikipedia 及 CAD
                        </p>

                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
